import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import twitterIcon from "../assets/images/twitter.svg";
import instagramIcon from "../assets/images/instagram.svg";
import facebookIcon from "../assets/images/facebook.svg";

const Footer = () => {
  return (
    <>
      <section className="flex flex-col justify-center items-center bg-black pb-10 pt-5">
        <div className="my-3 flex gap-5 items-center opacity-80">
          <a
            target="_blank"
            href="https://twitter.com/GB_lifestyle?t=CCkhjcE4RFIF7GBSjCAXvQ&s=09"
            className="h-10 w-10 bg-gray-50 rounded-full flex justify-center items-center cursor-pointer"
            rel="noreferrer"
          >
            <img src={twitterIcon} alt="twitter_icon" />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/greatbodylifestyle/"
            className="h-10 w-10 bg-gray-50 rounded-full flex justify-center items-center cursor-pointer"
            rel="noreferrer"
          >
            <img src={instagramIcon} alt="twitter_icon" />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/people/Great-Body-Lifestyle/100088155897576/"
            className="h-10 w-10 bg-gray-50 rounded-full flex justify-center items-center cursor-pointer"
            rel="noreferrer"
          >
            <img src={facebookIcon} alt="twitter_icon" />
          </a>
        </div>

        <div>
          <img src={logo} alt="" width={100} />
        </div>
        <div className="mt-5">
          <Link
            to="/terms-condition"
            className="px-5 border-r border-r-gray-600 text-gray-400"
          >
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" className="px-5 text-gray-400">
            Privacy Policy
          </Link>
        </div>
      </section>
    </>
  );
};

export default Footer;
