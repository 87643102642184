import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Menu from "../assets/images/menu.png";
import Close from "../assets/images/close.png";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleClickScroll = () => {
    const element = document.getElementById("app_download_section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <nav
        className={`fixed top-0 left-0 w-full flex flex-wrap items-center justify-between px-2 py-3 z-50 ${
          navbarOpen && "bg-black"
        }`}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="text-sm font-bold leading-relaxed  whitespace-nowrap uppercase text-white"
              to="/"
            >
              <img
                src={Logo}
                alt="logo"
                className="lg:w-[80px] sm:w-[70px] w-[60px]"
              />
            </Link>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent lg:hidden outline-none focus:outline-none flex justify-center items-center"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {!navbarOpen ? (
                <>
                  <img src={Menu} alt="menu" width="20px" />
                </>
              ) : (
                <img src={Close} alt="close" width="15px" />
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto  gap-4 my-3">
              <li>
                <button
                  className="text-black bg-primary px-8 py-2 rounded-lg text-sm font-medium"
                  onClick={handleClickScroll}
                >
                  Get App
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
