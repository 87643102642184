import "./App.css";
import { Route, Routes } from "react-router-dom";
import Main from "./view/main";
import Layout from "./layout/Layout";
import PrivayPolicy from "./view/privacy-policy";
import TermsAndCondition from "./view/terms-and-condition";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy-policy" element={<PrivayPolicy />} />
        <Route path="/terms-condition" element={<TermsAndCondition />} />
      </Routes>
    </Layout>
  );
}

export default App;
