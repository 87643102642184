import React from "react";

const TermsAndCondition = () => {
  return (
    <div
      data-custom-className="body"
      className="text-white pt-32 px-10 container mx-auto"
    >
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          align="center"
          className="MsoNormal"
          data-custom-className="title"
          style={{ textAlign: "left", lineHeight: "1.5" }}
        >
          <a name="_gm5sejt4p02f" />
          <strong>
            <span
              className="text-primary"
              style={{
                lineHeight: "150%",
                fontSize: "26px",
              }}
            >
              <bdt className="block-component" />
              <bdt className="question">TERMS AND CONDITIONS</bdt>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </strong>
        </div>
        <div
          align="center"
          className="MsoNormal"
          style={{ textAlign: "center", lineHeight: "1.5" }}
        >
          <a name="_7m5b3xg56u7y" />
        </div>
        <div
          align="center"
          className="MsoNormal"
          data-custom-className="subtitle"
          style={{ textAlign: "left", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          align="center"
          className="MsoNormal"
          data-custom-className="subtitle"
          style={{ textAlign: "left", lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "150%",

              color: "#A6A6A6",
              msoThemecolor: "background1",
              msoThemeshade: 166,
            }}
          >
            <span
              style={{
                color: "rgb(127, 127, 127)",
                fontSize: "15px",
                textAlign: "justify",
              }}
            >
              <strong>Last updated</strong>
            </span>
            <strong>
              <span
                style={{
                  color: "rgb(127,127,127)",
                  fontSize: "14.6667px",
                  textAlign: "justify",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <span
              style={{
                color: "rgb(127, 127, 127)",
                fontSize: "15px",
                textAlign: "justify",
              }}
            >
              <bdt
                className="block-container question question-in-editor"
                data-id="0d5ae8ae-7749-9afb-1fed-6556cb563dc0"
                data-type="question"
              >
                <strong>December 06, 2022</strong>
              </bdt>
            </span>
          </span>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      {/* <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <strong>
            <span data-custom-className="heading_1">TABLE OF CONTENTS</span>
          </strong>
        </div> */}
      {/* <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div> */}
      {/* <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#agreement">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-className="body_text">1. AGREEMENT TO TERMS</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#ip">
            <span data-custom-className="body_text">
              2. INTELLECTUAL PROPERTY RIGHTS
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#userreps">
            <span data-custom-className="body_text">3. USER REPRESENTATIONS</span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#payment">
            <span data-custom-className="body_text">4. FEES AND PAYMENT</span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#freetrial">
            <span data-custom-className="body_text">5. FREE TRIAL</span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#cancel">
            <span data-custom-className="body_text">6. CANCELLATION</span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#software">
            <span data-custom-className="body_text">7. SOFTWARE</span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#prohibited">
            <span data-custom-className="body_text">8. PROHIBITED ACTIVITIES</span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#ugc">
            <span data-custom-className="body_text">
              9. USER GENERATED CONTRIBUTIONS
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#license">
            <span data-custom-className="body_text">10. CONTRIBUTION LICENSE</span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#mobile">
            <span data-custom-className="body_text">
              11. MOBILE APPLICATION LICENSE
            </span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#submissions">
            <span data-custom-className="body_text">12. SUBMISSIONS</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#thirdparty">
            <span data-custom-className="body_text">
              13. THIRD-PARTY WEBSITES AND CONTENT
            </span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#usrights">
            <span data-custom-className="body_text">
              14. U.S. GOVERNMENT RIGHTS
            </span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#sitemanage">
            <span data-custom-className="body_text">15. SITE MANAGEMENT</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#privacypolicy1">
            <span data-custom-className="body_text">16. PRIVACY POLICY</span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <span data-custom-className="body_text">
            <bdt className="block-component" />
            <bdt className="block-component" />
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#terms">
            <span data-custom-className="body_text">17. TERM AND TERMINATION</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#modifications">
            <span data-custom-className="body_text">
              18. MODIFICATIONS AND INTERRUPTIONS
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#law">
            <span data-custom-className="body_text">19. GOVERNING LAW</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#disputes">
            <span data-custom-className="body_text">20. DISPUTE RESOLUTION</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#corrections">
            <span data-custom-className="body_text">21. CORRECTIONS</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#disclaimer">
            <span data-custom-className="body_text">22. DISCLAIMER</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#liability">
            <span data-custom-className="body_text">
              23. LIMITATIONS OF LIABILITY
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#indemnification">
            <span data-custom-className="body_text">24. INDEMNIFICATION</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#userdata">
            <span data-custom-className="body_text">25. USER DATA</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#electronic">
            <span data-custom-className="body_text">
              26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span data-custom-className="body_text" />
          </bdt>
          <a data-custom-className="link" href="#california">
            <span data-custom-className="body_text">
              27. CALIFORNIA USERS AND RESIDENTS
            </span>
          </a>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-className="body_text" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#misc">
            <span data-custom-className="body_text">28. MISCELLANEOUS</span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-className="link" href="#contact">
            <span data-custom-className="body_text">29. CONTACT US</span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div> */}
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="agreement"
          style={{ lineHeight: "1.5" }}
        >
          <a name="_6aa3gkhykvst" />
          <strong>
            <span
              className="text-primary"
              style={{
                lineHeight: "115%",

                fontSize: "19px",
              }}
            >
              1. AGREEMENT TO TERMS
            </span>
          </strong>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",
            }}
          >
            These <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> constitute a legally
            binding agreement made between you, whether personally or on behalf
            of an entity (“you”) and{" "}
            <bdt
              className="block-container question question-in-editor"
              data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
              data-type="question"
            >
              Great Body Lifestyle LTD
            </bdt>
          </span>
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",

              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <bdt className="block-component" />
            &nbsp;
          </span>
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",
            }}
          >
            ("
            <bdt className="block-component" />
            <strong>Company</strong>
            <bdt className="statement-end-if-in-editor" />
            ", “<strong>we</strong>”, “<strong>us</strong>”, or “
            <strong>our</strong>”), concerning your access to and use of the{" "}
            <bdt
              className="block-container question question-in-editor"
              data-id="fdf30452-99b8-c68b-5cdf-34af764cd1fd"
              data-type="question"
            >
              <a
                href="https://www.greatbodylifestyle.com"
                target="_blank"
                data-custom-className="link"
              >
                https://www.greatbodylifestyle.com
              </a>
            </bdt>{" "}
            website as well as any other media form, media channel, mobile
            website or mobile application related, linked, or otherwise
            connected thereto (collectively, the “Site”).
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",

                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",

                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",

                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <bdt className="question">
                    <bdt className="block-component" />
                  </bdt>
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",

                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="block-component" />
                  </span>{" "}
                  We are registered in <bdt className="block-component" />
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-className="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span data-custom-className="body_text">
                          <span>
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-className="body_text">
                                <span style={{ fontSize: "15px" }}>
                                  <span data-custom-className="body_text">
                                    <span>
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>{" "}
                  the <bdt className="question">United Kingdom</bdt>
                  <bdt className="block-component" />
                </span>
              </span>
              <bdt className="block-component" />
            </span>
          </span>{" "}
          and have our registered office at{" "}
          <bdt className="question">__________</bdt>
          <bdt className="block-component" />,{" "}
          <bdt className="question">__________</bdt>
          <span style={{ fontSize: "15px" }}>
            <span data-custom-className="body_text">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-className="body_text">
                  <span>
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",

              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",

                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="statement-end-if-in-editor">.</bdt>
              <bdt className="else-block" />
            </span>
          </span>
          <bdt className="statement-end-if-in-editor" />
          <bdt className="block-component" /> Our VAT number is{" "}
          <bdt className="question">427669165.</bdt>
          <bdt className="statement-end-if-in-editor" /> You agree that by
          accessing the Site, you have read, understood, and agree to be bound
          by all of these <bdt className="block-component" />
          <bdt className="question">Terms and Conditions</bdt>
          <bdt className="statement-end-if-in-editor" />
          <bdt
            className="block-container if"
            data-type="if"
            id="c4a4c609-d962-97d8-9b96-d3492402adad"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="separate_agreement_option"
                data-type="statement"
              />
            </bdt>
            . IF YOU DO NOT AGREE WITH ALL OF THESE{" "}
            <bdt className="block-component" />
            <bdt className="question">TERMS AND CONDITIONS</bdt>
            <bdt className="statement-end-if-in-editor" />, THEN YOU ARE
            EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE
            USE IMMEDIATELY.
          </bdt>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            <span
              style={{
                lineHeight: "115%",
              }}
            >
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these{" "}
              <bdt className="block-component" />
              <bdt className="question">Terms and Conditions</bdt>
              <bdt className="statement-end-if-in-editor" />{" "}
              <bdt className="block-component" />
              from time to time
              <bdt className="else-block" />. We will alert you about any
              changes by updating the “Last updated” date of these{" "}
              <bdt className="block-component" />
              <bdt className="question">Terms and Conditions</bdt>
              <bdt className="statement-end-if-in-editor" />, and you waive any
              right to receive specific notice of each such change. Please
              ensure that you check the applicable Terms every time you use our
              Site so that you understand which Terms apply. You will be subject
              to, and will be deemed to have been made aware of and to have
              accepted, the changes in any revised{" "}
              <bdt className="block-component" />
              <bdt className="question">Terms and Conditions</bdt>
              <bdt className="statement-end-if-in-editor" /> by your continued
              use of the Site after the date such revised{" "}
              <bdt className="block-component" />
              <bdt className="question">Terms and Conditions</bdt>
              <bdt className="statement-end-if-in-editor" /> are posted.
            </span>
          </span>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",
            }}
          >
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",

              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <bdt className="block-component" />
            <bdt className="block-component" />
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: 1 }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",

              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            The Site is not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use this Site. You may not use the Site in a way that would violate
            the Gramm-Leach-Bliley Act (GLBA).
            <bdt className="block-component" />
            <bdt className="statement-end-if-in-editor" />
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: 1 }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <bdt
            className="block-container if"
            data-type="if"
            id="a2595956-7028-dbe5-123e-d3d3a93ed076"
          >
            <bdt data-type="conditional-block">
              <span style={{ fontSize: "15px" }}>
                <bdt
                  className="block-component"
                  data-record-question-key="user_o18_option"
                  data-type="statement"
                />
              </span>{" "}
              <bdt data-type="body">
                <span
                  style={{
                    fontSize: "15px",
                    lineHeight: "115%",

                    color: "white",
                  }}
                >
                  The Site is intended for users who are at least 18 years old.
                  Persons under the age of 18 are not permitted to use or
                </span>
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",

                    color: "white",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    lineHeight: "115%",

                    color: "white",
                  }}
                >
                  register for the Site.
                </span>
              </bdt>
            </bdt>
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <span style={{ color: "white", fontSize: "15px" }}>
                  <bdt className="block-component" />
                </span>
              </bdt>
            </bdt>
          </bdt>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="ip"
          style={{ lineHeight: "1.5" }}
        >
          <a name="_b6y29mp52qvx" />
          <strong>
            <span
              className="text-primary"
              style={{
                lineHeight: "115%",

                fontSize: "19px",
              }}
            >
              2. INTELLECTUAL PROPERTY RIGHTS
            </span>
          </strong>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            Unless otherwise indicated, the Site is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the “Content”) and the trademarks, service marks, and
            logos contained therein (the “Marks”) are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the United States, international copyright laws,
            and international conventions. The Content and the Marks are
            provided on the Site “AS IS” for your information and personal use
            only. Except as expressly provided in these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" />, no part of the Site
            and no Content or Marks may be copied, reproduced, aggregated,
            republished, uploaded, posted, publicly displayed, encoded,
            translated, transmitted, distributed, sold, licensed, or otherwise
            exploited for any commercial purpose whatsoever, without our express
            prior written permission.
          </span>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            Provided that you are eligible to use the Site, you are granted a
            limited license to access and use the Site and to download or print
            a copy of any portion of the Content to which you have properly
            gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to the Site,
            the Content and the Marks.
          </span>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="userreps"
          style={{ lineHeight: "1.5" }}
        >
          <a name="_5hg7kgyv9l8z" />
          <strong>
            <span
              className="text-primary"
              style={{
                lineHeight: "115%",

                fontSize: "19px",
              }}
            >
              3. USER REPRESENTATIONS
            </span>
          </strong>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            By using the Site, you represent and warrant that:
          </span>
          <bdt
            className="block-container if"
            data-type="if"
            id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="user_account_option"
                data-type="statement"
              >
                <span style={{ fontSize: "15px" }} />
              </bdt>
              &nbsp;
            </bdt>
            <span style={{ color: "white", fontSize: "15px" }}>(</span>
            <span style={{ fontSize: "15px" }}>
              <span style={{ color: "white" }}>1</span>
            </span>
            <span style={{ color: "white", fontSize: "15px" }}>
              ) you have the legal capacity and you agree to comply with these{" "}
              <bdt className="block-component" />
              <bdt className="question">Terms and Conditions</bdt>
              <bdt className="statement-end-if-in-editor" />;{" "}
              <bdt className="block-component" />
            </span>
          </bdt>
          <span style={{ color: "white", fontSize: "15px" }}>(</span>
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "white" }}>2</span>
          </span>
          <span style={{ color: "white", fontSize: "15px" }}>
            ) you are not a minor in the jurisdiction in which you reside
            <bdt
              className="block-container if"
              data-type="if"
              id="76948fab-ec9e-266a-bb91-948929c050c9"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_o18_option"
                  data-type="statement"
                />
              </bdt>
              ;
            </bdt>
          </span>
          <span style={{ color: "white", fontSize: "11pt" }}>&nbsp;</span>
          <span style={{ color: "white", fontSize: "15px" }}>(</span>
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "white" }}>3</span>
          </span>
          <span style={{ color: "white", fontSize: "15px" }}>
            ) you will not access the Site through automated or non-human means,
            whether through a bot, script or otherwise;
          </span>
          <span style={{ color: "white", fontSize: "11pt" }}>&nbsp;</span>
          <span style={{ color: "white", fontSize: "15px" }}>(</span>
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "white" }}>4</span>
          </span>
          <span style={{ color: "white", fontSize: "15px" }}>
            ) you will not use the Site for any illegal or unauthorized purpose;
            and (
          </span>
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "white" }}>5</span>
          </span>
          <span style={{ color: "white", fontSize: "15px" }}>
            ) your use of the Site will not violate any applicable law or
            regulation.
          </span>
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <bdt
            className="block-container if"
            data-type="if"
            id="d13abc62-cc6f-e059-7fd6-cb6208085aa7"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="user_account_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="payment"
          style={{ lineHeight: "1.5" }}
        >
          <a name="_ynub0jdx8pob" />
          <strong>
            <span
              className="text-primary"
              style={{
                lineHeight: "115%",

                fontSize: "19px",
              }}
            >
              4. FEES AND PAYMENT
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",

              color: "white",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <br />
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ color: "white", fontSize: "15px" }}>
            We accept the following forms of payment:
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
            <div
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: 1, textAlign: "left" }}
            >
              <br />
            </div>
            <div
              className="MsoNormal"
              data-custom-className="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt
                  className="block-container forloop"
                  data-type="forloop"
                  id="a6e21b8e-b955-c546-15dd-bb6eda2fe88a"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="forloop-component"
                      data-record-question-key="payment_list"
                      data-type="statement"
                    />
                  </bdt>
                </bdt>
                <bdt
                  className="block-container forloop"
                  data-type="forloop"
                  id="a6e21b8e-b955-c546-15dd-bb6eda2fe88a"
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <span style={{ textIndent: "-24.7333px" }}>
                        - &nbsp;Visa
                      </span>
                    </bdt>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-className="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                </bdt>
              </span>
            </div>
          </div>
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",

              color: "white",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <div
              className="MsoNormal"
              style={{ lineHeight: 1, textAlign: "left" }}
            >
              <br />
            </div>
          </span>
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            <div
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ lineHeight: "115%", color: "white" }}>
                You may be required to purchase or pay a fee to access some of
                our services. You agree to provide current, complete, and
                accurate purchase and account information for all purchases made
                via the Site. You further agree to promptly update account and
                payment information, including email address, payment method,
                and payment card expiration date, so that we can complete your
                transactions and contact you as needed. We bill you through an
                online billing account for purchases made via the Site. Sales
                tax will be added to the price of purchases as deemed required
                by us. We may change prices at any time. All payments shall be
                in <bdt className="question">GBP</bdt>.
              </span>
            </div>
          </span>
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",

              color: "white",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <div
              className="MsoNormal"
              style={{ lineHeight: 1, textAlign: "left" }}
            >
              <br />
            </div>
            <div
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "115%",
                  color: "white",
                }}
              >
                You agree to pay all charges or fees at the prices then in
                effect for your purchases, and you authorize us to charge your
                chosen payment provider for any such amounts upon making your
                purchase.{" "}
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="2955e4e9-b2fc-b1ce-6d2e-99ed4a0c390d"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="recurring_charge_option"
                      data-type="statement"
                    />
                  </bdt>
                </bdt>
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="2955e4e9-b2fc-b1ce-6d2e-99ed4a0c390d"
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">If&nbsp;</bdt>
                  </bdt>
                </bdt>
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="2955e4e9-b2fc-b1ce-6d2e-99ed4a0c390d"
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      your purchase is subject to recurring charges, then you
                      consent to our charging your payment method on a recurring
                      basis without requiring your prior approval for each
                      recurring charge, until you notify us of your
                      cancellation.
                    </bdt>
                  </bdt>
                </bdt>
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="2955e4e9-b2fc-b1ce-6d2e-99ed4a0c390d"
                >
                  <bdt
                    className="statement-end-if-in-editor"
                    data-type="close"
                  />
                </bdt>
              </span>
            </div>
            <br />
          </span>
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            <div
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ lineHeight: "115%", color: "white" }}>
                We reserve the right to correct any errors or mistakes in
                pricing, even if we have already requested or received payment.
                We also reserve the right to refuse any order placed through the
                Site.
              </span>
            </div>
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <div
            className="MsoNormal"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <a name="_drzjqilz2ujm" />
          </div>
          <bdt
            className="block-container if"
            data-type="if"
            id="4380167d-5abe-b98f-f389-f707429e6c52"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="free_trial_option"
                data-type="statement"
              />{" "}
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-className="heading_1"
                  id="freetrial"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      className="text-primary"
                      style={{
                        lineHeight: "115%",

                        fontSize: "19px",
                      }}
                    >
                      5. FREE TRIAL
                    </span>
                  </strong>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <bdt className="block-container if" data-type="if">
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",
                      color: "white",
                    }}
                  >
                    We offer a{" "}
                    <bdt
                      className="block-container question question-in-editor"
                      data-id="3f053c87-320c-4c3a-2d73-c199bf826436"
                      data-type="question"
                    >
                      __________
                    </bdt>
                    -day free trial to new users who register with the Site.{" "}
                    <bdt className="block-component" />
                    <bdt className="question">
                      The account will not be charged and the subscription will
                      be suspended until upgraded to a paid version
                    </bdt>
                  </span>
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      color: "white",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        lineHeight: "115%",
                        color: "white",
                      }}
                    >
                      <bdt className="else-block" />
                    </span>{" "}
                    at the end of the free trial.
                  </span>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <bdt className="block-container if" data-type="if">
            <bdt className="statement-end-if-in-editor" data-type="close">
              <span style={{ fontSize: "15px" }} />
            </bdt>
          </bdt>
          <div
            className="MsoNormal"
            data-custom-className="heading_1"
            id="cancel"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
            <a name="_e993diqrk0qx" />
            <strong>
              <span
                className="text-primary"
                style={{
                  lineHeight: "115%",

                  fontSize: "19px",
                }}
              >
                6. CANCELLATION
              </span>
            </strong>
          </div>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <div
            className="MsoNormal"
            data-custom-className="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                color: "white",
              }}
            >
              <bdt
                className="block-container if"
                data-type="if"
                id="cef55cf6-3a65-8031-d0c3-cffe36e64f10"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="return_option"
                    data-type="statement"
                  />
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="cef55cf6-3a65-8031-d0c3-cffe36e64f10"
              >
                <bdt data-type="conditional-block">
                  <bdt data-type="body">All purchases are non-refundable.</bdt>
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="cef55cf6-3a65-8031-d0c3-cffe36e64f10"
              >
                <bdt data-type="conditional-block">
                  <bdt data-type="body">&nbsp;</bdt>
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="cef55cf6-3a65-8031-d0c3-cffe36e64f10"
              >
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
              You can cancel your subscription at any time
            </span>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                color: "white",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                color: "white",
              }}
            >
              <bdt
                className="block-container if"
                data-type="if"
                id="1a40a488-252c-f087-02cc-5e0de451aa88"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="cancel_how"
                    data-type="statement"
                  />
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="1a40a488-252c-f087-02cc-5e0de451aa88"
              >
                <bdt data-type="conditional-block">
                  <bdt data-type="body">by logging</bdt>
                </bdt>
              </bdt>
            </span>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                color: "white",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt
                className="block-container if"
                data-type="if"
                id="1a40a488-252c-f087-02cc-5e0de451aa88"
              >
                <bdt data-type="conditional-block">
                  <bdt data-type="body">&nbsp;</bdt>
                </bdt>
              </bdt>
            </span>
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                color: "white",
              }}
            >
              <bdt
                className="block-container if"
                data-type="if"
                id="1a40a488-252c-f087-02cc-5e0de451aa88"
              >
                <bdt data-type="conditional-block">
                  <bdt data-type="body">into your account</bdt>
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="1a40a488-252c-f087-02cc-5e0de451aa88"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="null"
                    data-type="statement"
                  />
                </bdt>
                . Your cancellation will take effect at the end of the current
                paid term.
              </bdt>
            </span>
          </div>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "1.5" }}
        >
          <div
            className="MsoNormal"
            data-custom-className="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                color: "white",
              }}
            >
              If you are unsatisfied with our services, please email us at{" "}
              <bdt
                className="block-container question question-in-editor"
                data-id="7c40698b-5cc8-bb58-245d-2fbd1b57dfba"
                data-type="question"
              >
                contact@greatbodylifestyle.com
              </bdt>
              <bdt className="block-component" />
            </span>
            .
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="b671a946-ab46-9fff-9cee-e88149335e8e"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="software_option"
                  data-type="statement"
                />
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-className="heading_1"
                    id="software"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <strong>
                      <span
                        className="text-primary"
                        style={{
                          lineHeight: "115%",

                          fontSize: "19px",
                        }}
                      >
                        7. SOFTWARE
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-className="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        lineHeight: "115%",
                        color: "white",
                      }}
                    >
                      We may include software for use in connection with our
                      services. If such software is accompanied by an end user
                      license agreement (“EULA”), the terms of the EULA will
                      govern your use of the software. If such software is not
                      accompanied by a EULA, then we grant to you a
                      non-exclusive, revocable, personal, and non-transferable
                      license to use such software solely in connection with our
                      services and in accordance with these{" "}
                      <bdt className="block-component" />
                      <bdt className="question">Terms and Conditions</bdt>
                      <bdt className="statement-end-if-in-editor" />. Any
                      Software and any related documentation is provided “as is”
                      without warranty of any kind, either express or implied,
                      including, without limitation, the implied warranties of
                      merchantability, fitness for a particular purpose, or
                      non-infringement. You accept any and all risk arising out
                      of use or performance of any Software. You may not
                      reproduce or redistribute any software except in
                      accordance with the EULA or these{" "}
                      <bdt className="block-component" />
                      <bdt className="question">Terms and Conditions</bdt>
                      <bdt className="statement-end-if-in-editor" />.
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-container if" data-type="if">
              <bdt className="statement-end-if-in-editor" data-type="close" />
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-className="heading_1"
            id="prohibited"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <a name="_h284p8mrs3r7" />
            <strong>
              <span
                className="text-primary"
                style={{
                  lineHeight: "115%",

                  fontSize: "19px",
                }}
              >
                8. PROHIBITED ACTIVITIES
              </span>
            </strong>
          </div>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "115%",

              color: "white",
            }}
          >
            You may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavors except those that are
            specifically endorsed or approved by us.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "115%" }}
        >
          <div
            className="MsoNormal"
            data-custom-className="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "15px",
                lineHeight: "16.8667px",
                color: "white",
              }}
            >
              As a user of the Site, you agree not to:
            </span>
          </div>
          <ul style={{ listStyle: "square", paddingInlineStart: "40px" }}>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "justify",
                    textIndent: "-29.4px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",

                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                    color: "white",
                  }}
                >
                  Systematically retrieve data or other content from the Site to
                  create or compile, directly or indirectly, a collection,
                  compilation, database, or directory without written permission
                  from us.
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",
                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Trick, defraud, or mislead us and other users,
                        especially in any attempt to learn sensitive account
                        information such as user passwords.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Circumvent, disable, or otherwise interfere with
                        security-related features of the Site, including
                        features that prevent or restrict the use or copying of
                        any Content or enforce limitations on the use of the
                        Site and/or the Content contained therein.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Disparage, tarnish, or otherwise harm, in our opinion,
                        us and/or the Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Use any information obtained from the Site in order to
                        harass, abuse, or harm another person.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Make improper use of our support services or submit
                        false reports of abuse or misconduct.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Use the Site in a manner inconsistent with any
                        applicable laws or regulations.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Engage in unauthorized framing of or linking to the
                        Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Upload or transmit (or attempt to upload or to transmit)
                        viruses, Trojan horses, or other material, including
                        excessive use of capital letters and spamming
                        (continuous posting of repetitive text), that interferes
                        with any party’s uninterrupted use and enjoyment of the
                        Site or modifies, impairs, disrupts, alters, or
                        interferes with the use, features, functions, operation,
                        or maintenance of the Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Engage in any automated use of the system, such as using
                        scripts to send comments or messages, or using any data
                        mining, robots, or similar data gathering and extraction
                        tools.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Delete the copyright or other proprietary rights notice
                        from any Content.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Attempt to impersonate another user or person or use the
                        username of another user.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Upload or transmit (or attempt to upload or to transmit)
                        any material that acts as a passive or active
                        information collection or transmission mechanism,
                        including without limitation, clear graphics interchange
                        formats (“gifs”), 1×1 pixels, web bugs, cookies, or
                        other similar devices (sometimes referred to as
                        “spyware” or “passive collection mechanisms” or “pcms”).
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Interfere with, disrupt, or create an undue burden on
                        the Site or the networks or services connected to the
                        Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Harass, annoy, intimidate, or threaten any of our
                        employees or agents engaged in providing any portion of
                        the Site to you.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Attempt to bypass any measures of the Site designed to
                        prevent or restrict access to the Site, or any portion
                        of the Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Copy or adapt the Site’s software, including but not
                        limited to Flash, PHP, HTML, JavaScript, or other code.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Except as permitted by applicable law, decipher,
                        decompile, disassemble, or reverse engineer any of the
                        software comprising or in any way making up a part of
                        the Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Except as may be the result of standard search engine or
                        Internet browser usage, use, launch, develop, or
                        distribute any automated system, including without
                        limitation, any spider, robot, cheat utility, scraper,
                        or offline reader that accesses the Site, or using or
                        launching any unauthorized script or other software.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Use a buying agent or purchasing agent to make purchases
                        on the Site.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Make any unauthorized use of the Site, including
                        collecting usernames and/or email addresses of users by
                        electronic or other means for the purpose of sending
                        unsolicited email, or creating user accounts by
                        automated means or under false pretenses.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span
                  style={{
                    lineHeight: "16.8667px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: "0px",
                      WebkitTextStrokeWidth: "0px",

                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",

                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "white",
                        }}
                      >
                        Use the Site as part of any effort to compete with us or
                        otherwise use the Site and/or the Content for any
                        revenue-generating endeavor or commercial enterprise.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt className="forloop-component" />
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt className="question">
                  Use the Site to advertise or offer to sell goods and services.
                </bdt>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt className="forloop-component" />
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-className="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt className="question">
                  Sell or otherwise transfer your profile.
                </bdt>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "16.8667px",
                  color: "white",
                }}
              >
                <bdt className="forloop-component" />
              </span>
            </li>
          </ul>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <a name="_zbbv9tgty199" />
        </div>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <bdt className="block-container if" data-type="if">
          <bdt data-type="conditional-block">
            <bdt data-type="body">
              <div
                className="MsoNormal"
                data-custom-className="heading_1"
                id="ugc"
                style={{ lineHeight: "1.5" }}
              >
                <strong>
                  <span
                    className="text-primary"
                    style={{
                      lineHeight: "115%",

                      fontSize: "19px",
                    }}
                  >
                    9. USER GENERATED CONTRIBUTIONS
                  </span>
                </strong>
              </div>
            </bdt>
          </bdt>
        </bdt>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <bdt className="block-container if" data-type="if">
          <bdt data-type="conditional-block">
            <bdt data-type="body">
              <div
                className="MsoNormal"
                style={{ textAlign: "justify", lineHeight: "115%" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "16.8667px",
                      color: "white",
                    }}
                  >
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="user_post_content_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                        The Site does not offer users to submit or post content.
                        <bdt className="block-component">
                          <bdt className="block-component" />
                        </bdt>
                      </bdt>
                    </bdt>
                  </span>
                </div>
              </div>
            </bdt>
          </bdt>
        </bdt>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <bdt className="block-container if" data-type="if">
          <bdt data-type="conditional-block">
            <bdt data-type="body">
              <div
                className="MsoNormal"
                data-custom-className="heading_1"
                id="license"
                style={{ lineHeight: "1.5" }}
              >
                <strong>
                  <span
                    className="text-primary"
                    style={{
                      lineHeight: "115%",

                      fontSize: "19px",
                    }}
                  >
                    10. CONTRIBUTION LICENSE
                  </span>
                </strong>
              </div>
            </bdt>
          </bdt>
        </bdt>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <br />
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <bdt className="block-container if" data-type="if">
          <bdt data-type="conditional-block">
            <bdt data-type="body">
              <div
                className="MsoNormal"
                data-custom-className="body_text"
                style={{ lineHeight: "1.5" }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    lineHeight: "115%",

                    color: "white",
                  }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="user_post_content_option"
                        data-type="statement"
                      />
                    </bdt>
                  </bdt>
                </span>
              </div>
              <div
                className="MsoNormal"
                data-custom-className="body_text"
                style={{ lineHeight: "1.5" }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    lineHeight: "115%",

                    color: "white",
                  }}
                >
                  You and the Site agree that we may access, store, process, and
                  use any information and personal data that you provide
                  following the terms of the Privacy Policy and your choices
                  (including settings).
                </span>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                className="MsoNormal"
                data-custom-className="body_text"
                style={{ lineHeight: "1.5" }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    lineHeight: "115%",

                    color: "white",
                  }}
                >
                  By submitting suggestions or other feedback regarding the
                  Site, you agree that we can use and share &nbsp;such feedback
                  for any purpose without compensation to you.
                  <bdt className="block-component" />
                </span>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt className="statement-end-if-in-editor" data-type="close">
                    <span style={{ fontSize: "15px" }} />
                  </bdt>
                </bdt>
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="review_option"
                      data-type="statement"
                    />
                  </bdt>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="mobile_app_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-className="heading_1"
                          id="mobile"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              className="text-primary"
                              style={{
                                lineHeight: "115%",

                                fontSize: "19px",
                              }}
                            >
                              11. MOBILE APPLICATION LICENSE
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="heading_2"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",

                              fontSize: "15px",
                            }}
                          >
                            Use License
                          </span>
                        </strong>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <span
                            style={{
                              lineHeight: "115%",

                              color: "white",
                            }}
                          >
                            If you access the Site via a mobile application,
                            then we grant you a revocable, non-exclusive,
                            non-transferable, limited right to install and use
                            the mobile application on wireless electronic
                            devices owned or controlled by you, and to access
                            and use the mobile application on such devices
                            strictly in accordance with the terms and conditions
                            of this mobile application license contained in
                            these <bdt className="block-component" />
                            <bdt className="question">Terms and Conditions</bdt>
                            <bdt className="statement-end-if-in-editor" />. You
                            shall not: (1) except as permitted by applicable
                            law, decompile, reverse engineer, disassemble,
                            attempt to derive the source code of, or decrypt the
                            application; (2) make any modification, adaptation,
                            improvement, enhancement, translation, or derivative
                            work from the application; (3) violate any
                            applicable laws, rules, or regulations in connection
                            with your access or use of the application; (4)
                            remove, alter, or obscure any proprietary notice
                            (including any notice of copyright or trademark)
                            posted by us or the licensors of the application;
                            (5) use the application for any revenue generating
                            endeavor, commercial enterprise, or other purpose
                            for which it is not designed or intended; (6) make
                            the application available over a network or other
                            environment permitting access or use by multiple
                            devices or users at the same time; (7) use the
                            application for creating a product, service, or
                            software that is, directly or indirectly,
                            competitive with or in any way a substitute for the
                            application; (8) use the application to send
                            automated queries to any website or to send any
                            unsolicited commercial e-mail; or (9) use any
                            proprietary information or any of our interfaces or
                            our other intellectual property in the design,
                            development, manufacture, licensing, or distribution
                            of any applications, accessories, or devices for use
                            with the application.
                          </span>
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="heading_2"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <a name="_vzf0b5xscg" />
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                              }}
                            >
                              Apple and Android Devices
                            </span>
                          </strong>
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <span
                            style={{
                              lineHeight: "115%",

                              color: "white",
                            }}
                          >
                            The following terms apply when you use a mobile
                            application obtained from either the Apple Store or
                            Google Play (each an “App Distributor”) to access
                            the Site: (1) the license granted to you for our
                            mobile application is limited to a non-transferable
                            license to use the application on a device that
                            utilizes the Apple iOS or Android operating systems,
                            as applicable, and in accordance with the usage
                            rules set forth in the applicable App Distributor’s
                            terms of service; (2) we are responsible for
                            providing any maintenance and support services with
                            respect to the mobile application as specified in
                            the terms and conditions of this mobile application
                            license contained in these{" "}
                            <bdt className="block-component" />
                            <bdt className="question">Terms and Conditions</bdt>
                            <bdt className="statement-end-if-in-editor" /> or as
                            otherwise required under applicable law, and you
                            acknowledge that each App Distributor has no
                            obligation whatsoever to furnish any maintenance and
                            support services with respect to the mobile
                            application; (3) in the event of any failure of the
                            mobile application to conform to any applicable
                            warranty, you may notify the applicable App
                            Distributor, and the App Distributor, in accordance
                            with its terms and policies, may refund the purchase
                            price, if any, paid for the mobile application, and
                            to the maximum extent permitted by applicable law,
                            the App Distributor will have no other warranty
                            obligation whatsoever with respect to the mobile
                            application; (4) you represent and warrant that (i)
                            you are not located in a country that is subject to
                            a U.S. government embargo, or that has been
                            designated by the U.S. government as a “terrorist
                            supporting” country and (ii) you are not listed on
                            any U.S. government list of prohibited or restricted
                            parties; (5) you must comply with applicable
                            third-party terms of agreement when using the mobile
                            application, e.g., if you have a VoIP application,
                            then you must not be in violation of their wireless
                            data service agreement when using the mobile
                            application; and (6) you acknowledge and agree that
                            the App Distributors are third-party beneficiaries
                            of the terms and conditions in this mobile
                            application license contained in these{" "}
                            <bdt className="block-component" />
                            <bdt className="question">Terms and Conditions</bdt>
                            <bdt className="statement-end-if-in-editor" />, and
                            that each App Distributor will have the right (and
                            will be deemed to have accepted the right) to
                            enforce the terms and conditions in this mobile
                            application license contained in these{" "}
                            <bdt className="block-component" />
                            <bdt className="question">Terms and Conditions</bdt>
                            <bdt className="statement-end-if-in-editor" />{" "}
                            against you as a third-party beneficiary thereof.
                          </span>
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt className="statement-end-if-in-editor" data-type="close">
                    <span style={{ fontSize: "15px" }} />
                  </bdt>
                </bdt>
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="e9981d4e-3a93-85dd-654b-7ecdf4bfe7d2"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="socialnetwork_link_option"
                      data-type="statement"
                    />
                  </bdt>
                  <div
                    className="MsoNormal"
                    data-custom-className="heading_1"
                    id="submissions"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_wfq2hvrw11j4" />
                    <strong>
                      <span
                        className="text-primary"
                        style={{
                          lineHeight: "115%",

                          fontSize: "19px",
                        }}
                      >
                        12. SUBMISSIONS
                      </span>
                    </strong>
                  </div>
                </bdt>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",

                      color: "white",
                    }}
                  >
                    You acknowledge and agree that any questions, comments,
                    suggestions, ideas, feedback, or other information regarding
                    the Site ("Submissions") provided by you to us are
                    non-confidential and shall become our sole property. We
                    shall own exclusive rights, including all intellectual
                    property rights, and shall be entitled to the unrestricted
                    use and dissemination of these Submissions for any lawful
                    purpose, commercial or otherwise, without acknowledgment or
                    compensation to you. You hereby waive all moral rights to
                    any such Submissions, and you hereby warrant that any such
                    Submissions are original with you or that you have the right
                    to submit such Submissions. You agree there shall be no
                    recourse against us for any alleged or actual infringement
                    or misappropriation of any proprietary right in your
                    Submissions.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="36ce5a69-4560-4947-dc72-46e53e2d562a"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="3rd_party_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-className="heading_1"
                          id="thirdparty"
                          style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              className="text-primary"
                              style={{
                                lineHeight: "115%",

                                fontSize: "19px",
                              }}
                            >
                              13. THIRD-PARTY WEBSITES AND CONTENT
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-className="body_text"
                          style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              lineHeight: "115%",

                              color: "white",
                            }}
                          >
                            The Site may contain (or you may be sent via the
                            Site) links to other websites ("Third-Party
                            Websites") as well as articles, photographs, text,
                            graphics, pictures, designs, music, sound, video,
                            information, applications, software, and other
                            content or items belonging to or originating from
                            third parties ("Third-Party Content"). Such
                            Third-Party Websites and Third-Party Content are not
                            investigated, monitored, or checked for accuracy,
                            appropriateness, or completeness by us, and we are
                            not responsible for any Third-Party Websites
                            accessed through the Site or any Third-Party Content
                            posted on, available through, or installed from the
                            Site, including the content, accuracy,
                            offensiveness, opinions, reliability, privacy
                            practices, or other policies of or contained in the
                            Third-Party Websites or the Third-Party Content.
                            Inclusion of, linking to, or permitting the use or
                            installation of any Third-Party Websites or any
                            Third-Party Content does not imply approval or
                            endorsement thereof by us. If you decide to leave
                            the Site and access the Third-Party Websites or to
                            use or install any Third-Party Content, you do so at
                            your own risk, and you should be aware these{" "}
                            <bdt className="block-component" />
                            <bdt className="question">Terms and Conditions</bdt>
                            <bdt className="statement-end-if-in-editor" /> no
                            longer govern. You should review the applicable
                            terms and policies, including privacy and data
                            gathering practices, of any website to which you
                            navigate from the Site or relating to any
                            applications you use or install from the Site. Any
                            purchases you make through Third-Party Websites will
                            be through other websites and from other companies,
                            and we take no responsibility whatsoever in relation
                            to such purchases which are exclusively between you
                            and the applicable third party. You agree and
                            acknowledge that we do not endorse the products or
                            services offered on Third-Party Websites and you
                            shall hold us harmless from any harm caused by your
                            purchase of such products or services. Additionally,
                            you shall hold us harmless from any losses sustained
                            by you or harm caused to you relating to or
                            resulting in any way from any Third-Party Content or
                            any contact with Third-Party Websites.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <bdt className="block-container if" data-type="if">
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    >
                      <span style={{ fontSize: "15px" }} />
                    </bdt>
                  </bdt>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="14038561-dad7-be9d-370f-f8aa487b2570"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="advertiser_option"
                        data-type="statement"
                      >
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "justify", lineHeight: "115%" }}
                >
                  <div
                    className="MsoNormal"
                    data-custom-className="heading_1"
                    id="usrights"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <strong>
                      <span
                        className="text-primary"
                        style={{
                          lineHeight: "115%",

                          fontSize: "19px",
                        }}
                      >
                        14. U.S. GOVERNMENT RIGHTS
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "justify", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "justify", lineHeight: "115%" }}
                >
                  <div
                    className="MsoNormal"
                    data-custom-className="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        lineHeight: "115%",

                        color: "white",
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      Our services are “commercial items” as defined in Federal
                      Acquisition Regulation (“FAR”) 2.101. If our services are
                      acquired by or on behalf of any agency not within the
                      Department of Defense (“DOD”), our services are subject to
                      the terms of these <bdt className="block-component" />
                      <bdt className="question">Terms and Conditions</bdt>
                      <bdt className="statement-end-if-in-editor" /> in
                      accordance with FAR 12.212 (for computer software) and FAR
                      12.211 (for technical data). If our services are acquired
                      by or on behalf of any agency within the Department of
                      Defense, our services are subject to the terms of these{" "}
                      <bdt className="block-component" />
                      <bdt className="question">Terms and Conditions</bdt>
                      <bdt className="statement-end-if-in-editor" /> in
                      accordance with Defense Federal Acquisition Regulation
                      (“DFARS”) 227.7202-
                    </span>
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "115%",

                          color: "white",
                          backgroundImage: "initial",
                          backgroundPosition: "initial",
                          backgroundSize: "initial",
                          backgroundRepeat: "initial",
                          backgroundAttachment: "initial",
                          backgroundOrigin: "initial",
                          backgroundClip: "initial",
                        }}
                      >
                        3. In addition, DFARS 252.227-
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "15px",
                        lineHeight: "115%",

                        color: "white",
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      7015 applies to technical data acquired by the DOD. This
                      U.S. Government Rights clause is in lieu of, and
                      supersedes, any other FAR, DFARS, or other clause or
                      provision that addresses government rights in computer
                      software or technical data under these{" "}
                      <bdt className="block-component" />
                      <bdt className="question">Terms and Conditions</bdt>
                      <bdt className="statement-end-if-in-editor" />.
                    </span>
                  </div>
                  <div
                    className="MsoNormal"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        color: "white",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="14038561-dad7-be9d-370f-f8aa487b2570"
                      >
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                      </bdt>
                    </span>
                  </div>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-className="heading_1"
                  id="sitemanage"
                  style={{ lineHeight: "1.5" }}
                >
                  <a name="_wj13r09u8u3u" />
                  <strong>
                    <span
                      className="text-primary"
                      style={{
                        lineHeight: "115%",

                        fontSize: "19px",
                      }}
                    >
                      15. SITE MANAGEMENT
                    </span>
                  </strong>
                </div>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",

                      color: "white",
                    }}
                  >
                    We reserve the right, but not the obligation, to: (1)
                    monitor the Site for violations of these{" "}
                    <bdt className="block-component" />
                    <bdt className="question">Terms and Conditions</bdt>
                    <bdt className="statement-end-if-in-editor" />; (2) take
                    appropriate legal action against anyone who, in our sole
                    discretion, violates the law or these{" "}
                    <bdt className="block-component" />
                    <bdt className="question">Terms and Conditions</bdt>
                    <bdt className="statement-end-if-in-editor" />, including
                    without limitation, reporting such user to law enforcement
                    authorities; (3) in our sole discretion and without
                    limitation, refuse, restrict access to, limit the
                    availability of, or disable (to the extent technologically
                    feasible) any of your Contributions or any portion thereof;
                    (4) in our sole discretion and without limitation, notice,
                    or liability, to remove from the Site or otherwise disable
                    all files and content that are excessive in size or are in
                    any way burdensome to our systems; and (5) otherwise manage
                    the Site in a manner designed to protect our rights and
                    property and to facilitate the proper functioning of the
                    Site.
                  </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <a name="_jugvcvcw0oj9" />
                </div>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="privacy_policy_option"
                      data-type="statement"
                    />
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="heading_1"
                        id="privacypolicy1"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            className="text-primary"
                            style={{
                              lineHeight: "115%",

                              fontSize: "19px",
                            }}
                          >
                            16. PRIVACY POLICY
                          </span>
                        </strong>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            lineHeight: "115%",

                            color: "white",
                          }}
                        >
                          We care about data privacy and security. Please review
                          our Privacy Policy:{" "}
                          <strong>
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1"
                              data-type="question"
                            >
                              __________
                            </bdt>
                          </strong>
                          . By using the Site, you agree to be bound by our
                          Privacy Policy, which is incorporated into these{" "}
                          <bdt className="block-component" />
                          <bdt className="question">Terms and Conditions</bdt>
                          <bdt className="statement-end-if-in-editor" />. Please
                          be advised the Site is hosted in{" "}
                          <bdt className="block-component" />
                          the <bdt className="question">United Kingdom</bdt>
                          <bdt className="block-component" />. If you access the
                          Site from any other region of the world with laws or
                          other requirements governing personal data collection,
                          use, or disclosure that differ from applicable laws in{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              lineHeight: "115%",

                              color: "white",
                            }}
                          >
                            <bdt className="block-component" />
                            the <bdt className="question">United Kingdom</bdt>
                            <bdt className="block-component" />
                          </span>
                          , then through your continued use of the Site,
                        </span>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",

                            color: "white",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          &nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "15px",
                            lineHeight: "115%",

                            color: "white",
                          }}
                        >
                          you are transferring your data to{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              lineHeight: "115%",

                              color: "white",
                            }}
                          >
                            <bdt className="block-component" />
                            the <bdt className="question">United Kingdom</bdt>
                            <bdt className="block-component" />
                          </span>
                          , and you agree to have your data transferred to and
                          processed in{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              lineHeight: "115%",

                              color: "white",
                            }}
                          >
                            <bdt className="block-component" />
                            the <bdt className="question">United Kingdom</bdt>
                            <bdt className="block-component" />
                          </span>
                          . <bdt className="block-component" />
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="user_u13_option"
                                data-type="statement"
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    lineHeight: "115%",

                                    color: "white",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </bdt>
                            </bdt>
                          </bdt>
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt className="statement-end-if-in-editor" data-type="close">
                    <span style={{ fontSize: "15px" }} />
                  </bdt>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="7371467a-f2b5-2aff-cd0f-3379e970551e"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="privacy_policy_followup"
                        data-type="statement"
                        style={{ fontSize: "14.6667px" }}
                      >
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="923fc4bc-b171-82ba-b6eb-0a13c12d1b6b"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="copyright_agent_option"
                          data-type="statement"
                        />
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span style={{ fontSize: "15px" }}>
                            <bdt className="block-component" />
                          </span>
                        </bdt>
                      </bdt>
                    </bdt>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="95e88984-ac54-be9d-35de-f10fd010af14"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="no_agent_clause_option"
                          data-type="statement"
                        />
                      </bdt>
                      <bdt className="block-component" />
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="heading_1"
                  id="terms"
                  style={{ lineHeight: "1.5" }}
                >
                  <a name="_k3mndam4w6w1" />
                  <strong>
                    <span
                      className="text-primary"
                      style={{
                        lineHeight: "115%",

                        fontSize: "19px",
                      }}
                    >
                      17. TERM AND TERMINATION
                    </span>
                  </strong>
                </div>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",

                      color: "white",
                    }}
                  >
                    These <bdt className="block-component" />
                    <bdt className="question">Terms and Conditions</bdt>
                    <bdt className="statement-end-if-in-editor" /> shall remain
                    in full force and effect while you use the Site. WITHOUT
                    LIMITING ANY OTHER PROVISION OF THESE{" "}
                    <bdt className="block-component" />
                    <bdt className="question">TERMS AND CONDITIONS</bdt>
                    <bdt className="statement-end-if-in-editor" />, WE RESERVE
                    THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                    LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
                    BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON
                    OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
                    ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE{" "}
                    <bdt className="block-component" />
                    <bdt className="question">TERMS AND CONDITIONS</bdt>
                    <bdt className="statement-end-if-in-editor" /> OR OF ANY
                    APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
                    PARTICIPATION IN THE SITE OR DELETE{" "}
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="user_account_option"
                          data-type="statement"
                        />
                      </bdt>
                      ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
                      WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </bdt>
                  </span>
                </div>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: "15px" }}>
                    <span
                      style={{
                        lineHeight: "115%",

                        color: "white",
                      }}
                    >
                      If we terminate or suspend your account for any reason,
                      you are prohibited from registering and creating a new
                      account under your name, a fake or borrowed name, or the
                      name of any third party, even if you may be acting on
                      behalf of the third party. In addition to terminating or
                      suspending your account, we reserve the right to take
                      appropriate legal action, including without limitation
                      pursuing civil, criminal, and injunctive redress.
                    </span>
                  </span>
                </div>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-className="heading_1"
                  id="modifications"
                  style={{ lineHeight: "1.5" }}
                >
                  <a name="_e2dep1hfgltt" />
                  <strong>
                    <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                      <span
                        className="text-primary"
                        style={{ fontSize: "19px" }}
                      >
                        18. MODIFICATIONS AND INTERRUPTIONS
                      </span>
                    </span>
                  </strong>
                </div>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",

                      color: "white",
                    }}
                  >
                    We reserve the right to change, modify, or remove the
                    contents of the Site at any time or for any reason at our
                    sole discretion without notice. However, we have no
                    obligation to update any information on our Site. We also
                    reserve the right to modify or discontinue all or part of
                    the Site without notice at any time. We will not be liable
                    to you or any third party for any modification, price
                    change, suspension, or discontinuance of the Site.
                  </span>
                </div>
              </div>
              <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",

                      color: "white",
                    }}
                  >
                    We cannot guarantee the Site will be available at all times.
                    We may experience hardware, software, or other problems or
                    need to perform maintenance related to the Site, resulting
                    in interruptions, delays, or errors. We reserve the right to
                    change, revise, update, suspend, discontinue, or otherwise
                    modify the Site at any time or for any reason without notice
                    to you. You agree that we have no liability whatsoever for
                    any loss, damage, or inconvenience caused by your inability
                    to access or use the Site during any downtime or
                    discontinuance of the Site. Nothing in these{" "}
                    <bdt className="block-component" />
                    <bdt className="question">Terms and Conditions</bdt>
                    <bdt className="statement-end-if-in-editor" /> will be
                    construed to obligate us to maintain and support the Site or
                    to supply any corrections, updates, or releases in
                    connection therewith.
                  </span>
                </div>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-className="heading_1"
                  id="law"
                  style={{ lineHeight: "1.5" }}
                >
                  <a name="_p6vbf8atcwhs" />
                  <strong>
                    <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                      <span
                        style={{ fontSize: "19px" }}
                        className="text-primary"
                      >
                        19. GOVERNING LAW
                      </span>
                    </span>
                  </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",

                      color: "white",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="block-component" />
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-className="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: "15px" }}>
                    These conditions are governed by and interpreted following
                    the laws of <bdt className="block-component" />
                    the <bdt className="question">United Kingdom</bdt>
                    <bdt className="else-block" />, and the use of the United
                    Nations Convention of Contracts for the International Sale
                    of Goods is expressly excluded. If your habitual residence
                    is in the EU, and you are a consumer, you additionally
                    possess the protection provided to you by obligatory
                    provisions of the law of your country of residence.{" "}
                    <bdt className="question">Great Body Lifestyle LTD</bdt> and
                    yourself both agree to submit to the non-exclusive
                    jurisdiction of the courts of{" "}
                    <bdt className="question">London</bdt>, which means that you
                    may make a claim to defend your consumer protection rights
                    in regards to these Conditions of Use in{" "}
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                      the <bdt className="question">United Kingdom</bdt>
                      <bdt className="else-block" />
                    </span>
                    , or in the EU country in which you reside.
                    <bdt className="else-block" />
                  </span>
                </div>
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <br />
              </div>
              <div
                align="center"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-className="heading_1"
                  id="disputes"
                  style={{ lineHeight: "1.5" }}
                >
                  <a name="_v5i5tjw62cyw" />
                  <strong>
                    <span
                      className="text-primary"
                      style={{
                        lineHeight: "115%",

                        fontSize: "19px",
                      }}
                    >
                      20. DISPUTE RESOLUTION
                    </span>
                  </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt className="block-component" />
                    </bdt>
                  </bdt>
                </div>
              </div>
              <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component" />
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="heading_2"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>Informal Negotiations</strong>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <br />
              </div>
              <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: "15px" }}>To expedite r</span>
                        <span
                          style={{
                            fontSize: "15px",
                            lineHeight: "16.8667px",
                            color: "white",
                          }}
                        >
                          esolution and control the cost of any dispute,
                          controversy, or claim related to these{" "}
                          <bdt className="block-component" />
                          <bdt className="question">Terms and Conditions</bdt>
                          <bdt className="statement-end-if-in-editor" /> (each
                          "Dispute" and collectively, the “Disputes”) brought by
                          either you or us (individually, a “Party” and
                          collectively, the “Parties”), the Parties agree to
                          first attempt to negotiate any Dispute (except those
                          Disputes expressly provided below) informally for at
                          least{" "}
                          <bdt
                            className="block-container question question-in-editor"
                            data-id="33108d82-92fb-fbe9-6ef5-01cac7097351"
                            data-type="question"
                          >
                            thirty (30)
                          </bdt>{" "}
                          days before initiating arbitration. Such informal
                          negotiations commence upon written notice from one
                          Party to the other Party.
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <br />
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        style={{ lineHeight: "1.5" }}
                      />
                    </bdt>
                  </bdt>
                </bdt>
                <bdt className="block-container if" data-type="if">
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-className="heading_2"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            lineHeight: "16.8667px",
                            color: "white",
                          }}
                        >
                          <strong>Binding Arbitration</strong>
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <br />
              </div>
              <div
                className="MsoNormal"
                data-custom-className="body_text"
                style={{ lineHeight: "1.5" }}
              >
                <bdt className="block-component">
                  <span style={{ fontSize: "15px" }} />
                </bdt>
              </div>
            </bdt>
          </bdt>
        </bdt>
      </div>
      <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            Any dispute arising from the relationships between the Parties to
            this contract shall be determined by one arbitrator who will be
            chosen in accordance with the Arbitration and Internal Rules of the
            European Court of Arbitration being part of the European Centre of
            Arbitration having its seat in Strasbourg, and which are in force at
            the time the application for arbitration is filed, and of which
            adoption of this clause constitutes acceptance. The seat of
            arbitration shall be <bdt className="block-component" />
            <bdt className="question">London</bdt>,{" "}
            <bdt className="question">United Kingdom</bdt>
            <bdt className="else-block" />. The language of the proceedings
            shall be <bdt className="question">English</bdt>. Applicable rules
            of substantive law shall be the law of{" "}
            <bdt className="block-component" />
            the <bdt className="question">United Kingdom</bdt>
            <bdt className="else-block" />.
          </span>
          <bdt className="else-block">
            <span style={{ fontSize: "15px" }} />
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_2"
          style={{ lineHeight: "1.5" }}
        >
          <strong>Restrictions</strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_2"
          style={{ lineHeight: "1.5" }}
        >
          <bdt className="block-component">
            <span style={{ fontSize: "15px" }}>
              <strong />
            </span>
          </bdt>
          <strong>Exceptions to Informal Negotiations and Arbitration</strong>
          <bdt className="statement-end-if-in-editor">
            <strong />
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component" />
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations and binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                color: "white",
              }}
            >
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                color: "white",
              }}
            >
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "115%",
                  color: "white",
                }}
              >
                <bdt className="statement-end-if-in-editor">
                  <span style={{ fontSize: "15px" }}>
                    <span
                      style={{
                        fontSize: "15px",
                        lineHeight: "115%",
                        color: "white",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          lineHeight: "115%",
                          color: "white",
                        }}
                      >
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </bdt>
              </span>
            </span>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="corrections"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }} className="text-primary">
              21. CORRECTIONS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="disclaimer"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }} className="text-primary">
              22. DISCLAIMER
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="liability"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }} className="text-primary">
              23. LIMITATIONS OF LIABILITY
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            IN{" "}
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",

                color: "white",
              }}
            >
              NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
              TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
              EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
              LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING
              FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.{" "}
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "115%",

                  color: "white",
                }}
              >
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="limitations_liability_option"
                      data-type="statement"
                    />
                  </bdt>
                </bdt>
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">NOTWITHSTANDING</bdt>
                  </bdt>
                </bdt>
              </span>
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",

                  color: "white",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">&nbsp;</bdt>
                  </bdt>
                </bdt>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  lineHeight: "115%",

                  color: "white",
                }}
              >
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY
                      TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
                      OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO{" "}
                      <span
                        style={{
                          fontSize: "15px",
                          lineHeight: "115%",

                          color: "white",
                        }}
                      >
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                        >
                          <bdt data-type="conditional-block">
                            <bdt data-type="body">
                              <bdt
                                className="block-container if"
                                data-type="if"
                                id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                              >
                                <bdt data-type="conditional-block">
                                  <bdt
                                    className="block-component"
                                    data-record-question-key="limitations_liability_option"
                                    data-type="statement"
                                  />
                                </bdt>
                              </bdt>
                            </bdt>
                          </bdt>
                        </bdt>
                      </span>
                      <bdt data-type="if">
                        <bdt data-type="conditional-block">
                          <bdt data-type="body">
                            <bdt data-type="if">
                              <bdt data-type="conditional-block">
                                <bdt data-type="body">THE AMOUNT PAID,</bdt>
                              </bdt>
                            </bdt>
                          </bdt>
                        </bdt>
                      </bdt>
                      <bdt data-type="if">
                        &nbsp;
                        <bdt data-type="conditional-block">
                          <bdt data-type="body">
                            <bdt data-type="if">
                              <bdt data-type="conditional-block">
                                <bdt data-type="body">
                                  IF ANY, BY YOU TO US
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      lineHeight: "115%",

                                      color: "white",
                                    }}
                                  >
                                    <bdt
                                      className="block-container if"
                                      data-type="if"
                                      id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                                    >
                                      <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                          <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                                          >
                                            <bdt data-type="conditional-block">
                                              <bdt data-type="body">
                                                <bdt
                                                  className="block-container if"
                                                  data-type="if"
                                                  id="19e172cb-4ccf-1904-7c06-4251800ba748"
                                                >
                                                  <bdt data-type="conditional-block">
                                                    <bdt
                                                      className="block-component"
                                                      data-record-question-key="limilation_liability_time_option"
                                                      data-type="statement"
                                                    />
                                                  </bdt>
                                                </bdt>
                                              </bdt>
                                            </bdt>
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                      <bdt
                                        className="block-container if"
                                        data-type="if"
                                        id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                                      >
                                        <bdt data-type="conditional-block">
                                          <bdt data-type="body">
                                            <bdt
                                              className="block-container if"
                                              data-type="if"
                                              id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                                            >
                                              <bdt data-type="conditional-block">
                                                <bdt
                                                  className="block-component"
                                                  data-record-question-key="limitations_liability_option"
                                                  data-type="statement"
                                                />
                                              </bdt>
                                            </bdt>
                                          </bdt>
                                        </bdt>
                                      </bdt>
                                    </bdt>
                                  </span>
                                  .{" "}
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      lineHeight: "115%",

                                      color: "white",
                                    }}
                                  >
                                    <bdt
                                      className="block-container if"
                                      data-type="if"
                                      id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                                    >
                                      <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                          CERTAIN US STATE LAWS AND
                                          INTERNATIONAL LAWS DO NOT ALLOW
                                          LIMITATIONS ON IMPLIED WARRANTIES OR
                                          THE EXCLUSION OR LIMITATION OF CERTAIN
                                          DAMAGES. IF THESE LAWS APPLY TO YOU,
                                          SOME OR ALL OF THE ABOVE DISCLAIMERS
                                          OR LIMITATIONS MAY NOT APPLY TO YOU,
                                          AND YOU MAY HAVE ADDITIONAL RIGHTS.
                                        </bdt>
                                      </bdt>
                                    </bdt>
                                    <bdt
                                      className="block-container if"
                                      data-type="if"
                                      id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                                    >
                                      <bdt
                                        className="statement-end-if-in-editor"
                                        data-type="close"
                                      />
                                    </bdt>
                                  </span>
                                </bdt>
                              </bdt>
                            </bdt>
                          </bdt>
                        </bdt>
                      </bdt>
                    </bdt>
                  </bdt>
                </bdt>
              </span>
            </span>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="indemnification"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }} className="text-primary">
              24. INDEMNIFICATION
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of:{" "}
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",

                color: "white",
              }}
            >
              <bdt
                className="block-container if"
                data-type="if"
                id="475fffa5-05ca-def8-ac88-f426b238903c"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_post_content_option"
                    data-type="statement"
                  />
                </bdt>
              </bdt>
            </span>
            (1) use of the Site; (2) breach of these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" />; (3) any breach of
            your representations and warranties set forth in these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" />; (4) your violation
            of the rights of a third party, including but not limited to
            intellectual property rights; or (5) any overt harmful act toward
            any other user of the Site with whom you connected via the Site.
            Notwithstanding the foregoing, we reserve the right, at your
            expense, to assume the exclusive defense and control of any matter
            for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="userdata"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }} className="text-primary">
              25. USER DATA
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="electronic"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "19px" }} className="text-primary">
            <strong>
              26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </strong>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            Visiting{" "}
            <span
              style={{
                lineHeight: "115%",

                color: "white",
              }}
            >
              the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SITE. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </span>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="california"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "19px" }} className="text-primary">
            <strong>27. CALIFORNIA USERS AND RESIDENTS</strong>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor" />
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="misc"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }} className="text-primary">
              28. MISCELLANEOUS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            These <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> and any policies or
            operating rules posted by us on the Site or in respect to the Site
            constitute the entire agreement and understanding between you and
            us. Our failure to exercise or enforce any right or provision of
            these <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> shall not operate as
            a waiver of such right or provision. These{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> operate to the
            fullest extent permissible by law. We may assign any or all of our
            rights and obligations to others at any time. We shall not be
            responsible or liable for any loss, damage, delay, or failure to act
            caused by any cause beyond our reasonable control. If any provision
            or part of a provision of these <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> is determined to be
            unlawful, void, or unenforceable, that provision or part of the
            provision is deemed severable from these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> and does not affect
            the validity and enforceability of any remaining provisions. There
            is no joint venture, partnership, employment or agency relationship
            created between you and us as a result of these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> or use of the Site.
            You agree that these <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> will not be construed
            against us by virtue of having drafted them. You hereby waive any
            and all defenses you may have based on the electronic form of these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" /> and the lack of
            signing by the parties hereto to execute these{" "}
            <bdt className="block-component" />
            <bdt className="question">Terms and Conditions</bdt>
            <bdt className="statement-end-if-in-editor" />.
            <bdt className="block-component" />
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="heading_1"
          id="contact"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "19px" }} className="text-primary">
            <strong>29. CONTACT US</strong>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            In o
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",

                color: "white",
              }}
            >
              rder to resolve a complaint regarding the Site or to receive
              further information regarding use of the Site, please contact us
              at:
            </span>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",

                color: "white",
              }}
            >
              <bdt className="question">
                <strong>Great Body Lifestyle LTD</strong>
              </bdt>
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ fontSize: "15px" }}>
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",

                color: "white",
              }}
            >
              <strong>
                <span style={{ color: "white" }}>
                  <bdt className="question">__________</bdt>
                  <strong>
                    <span style={{ color: "white" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </strong>
                </span>
              </strong>
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ color: "white" }}>
              <span style={{ fontSize: "15px" }}>
                <bdt className="question">__________</bdt>
                <strong>
                  <span style={{ color: "white" }}>
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <strong>
                    <span style={{ color: "white" }}>
                      <bdt className="block-component" />
                    </span>
                  </strong>
                  <strong>
                    <span style={{ color: "white" }}>
                      <bdt className="block-component" />
                      <bdt className="block-component">
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "white",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              msoFareastFontFamily: "Calibri",
                              color: "white",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-className="body_text">
                                <span style={{ fontSize: "15px" }}>
                                  <span data-custom-className="body_text">
                                    <span style={{ color: "white" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span data-custom-className="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span data-custom-className="body_text">
                                              <span
                                                style={{
                                                  color: "white",
                                                }}
                                              >
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </bdt>
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span style={{ color: "white" }}>
            <strong>
              <span style={{ fontSize: "15px" }}>
                <bdt className="statement-end-if-in-editor">
                  <bdt className="block-component">
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        msoFareastFontFamily: "Calibri",
                        color: "white",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11.0pt",
                          lineHeight: "115%",
                          msoFareastFontFamily: "Calibri",
                          color: "white",
                          msoThemecolor: "text1",
                          msoThemetint: 166,
                        }}
                      >
                        <bdt className="question">United Kingdom</bdt>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </bdt>
                </bdt>
              </span>
            </strong>
          </span>
          <bdt className="statement-end-if-in-editor">
            <strong />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          data-custom-className="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <bdt className="question">
            <strong>
              <span style={{ fontSize: "15px" }} className="text-primary">
                contact@greatbodylifestyle.com
              </span>
            </strong>
          </bdt>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
