import React from "react";
import playStore from "../../assets/images/playstore.png";
import apple from "../../assets/images/apple.png";
import bgBanner from "../../assets/images/bannermobile.png";
import tShirt from "../../assets/images/t-shirt.png";
import polygen from "../../assets/images/polygen.png";
import featureBg1 from "../../assets/images/feature-bg-1.png";
import featureBg2 from "../../assets/images/feature-bg-2.png";
import mobileBg from "../../assets/images/section-mobile.svg";
import maskGroup from "../../assets/images/Mask-group.png";
import maskGroup1 from "../../assets/images/Mask-group-1.png";
import tshirtBunch from "../../assets/images/t-shirt-bunch1.png";
import weightLoss from "../../assets/images/weight-loss-men.png";
import supportOther from "../../assets/images/support-other.png";
import workout from "../../assets/images/workout.png";
import bunchOfMobile from "../../assets/images/mobile-bunch.png";

const Main = () => {
  return (
    <>
      <section className="grid md:grid-cols-2">
        <div className="p-6 2xl:pl-32 xl:pl-16 lg:pl-7 md:pl-6 md:pt-20 2xl:max-w-full flex flex-col justify-center md:order-none order-2">
          <div>
            <h1 className="text-4xl sm:text-5xl md:text-[2.75rem] lg:text-6xl xl:text-7xl  2xl:text-[5rem] text-white font-semibold tracking-normal sm:leading-sung  leading-tight  text-center md:text-start">
              Have your cake, <br />
              eat it too &
              <span className=" text-[#B1BFAB]">
                &nbsp;still <br />
                lose weight
              </span>
            </h1>
          </div>
          <div className="my-10 flex flex-col md:flex-row md:justify-start items-center gap-5">
            <a
              href="https://play.google.com/store/apps/details?id=com.micrasol.greatbodylifestyle"
              target="_blank"
              className="py-2 px-2 sm:px-4 lg:px-5 bg-primary flex justify-center items-center gap-2 rounded-2xl cursor-pointer"
              rel="noreferrer"
            >
              <div className="w-8 h-8 bg-black rounded-full flex justify-center items-center">
                <img src={playStore} alt="" />
              </div>
              <p className="font-semibold -tracking-tighter">Play Store</p>
            </a>
            <a
              href="https://apps.apple.com/us/app/great-body-lifestyle/id1658699566"
              target="_blank"
              className="py-2 px-2 sm:px-4 lg:px-5 bg-primary flex justify-center items-center gap-2 rounded-2xl cursor-pointer"
              rel="noreferrer"
            >
              <div className="w-8 h-8 bg-black rounded-full flex justify-center items-center">
                <img src={apple} alt="" />
              </div>
              <p className="font-semibold -tracking-tighter">App Store</p>
            </a>
          </div>
        </div>
        <div className="md:mt-0 sm:mt-20 mt-12 md:scale-100 scale-125 md:translate-x-0 sm:-translate-x-20 -translate-x-14 md:block flex justify-center overflow-hidden">
          <img src={bgBanner} alt="" />
        </div>
      </section>
      <section className="container mx-auto px-4 mb-10">
        <div className="flex flex-col md:flex-row sm:justify-center sm:flex gap-5 p-2">
          <div className="w-full md:w-1/2 xl:h-[320px] overflow-hidden relative">
            <img
              src={featureBg1}
              className="w-full max-h-full object-cover rounded-2xl mx-auto"
              alt=""
            />
            <div className="absolute w-full h-full  top-0  flex items-center justify-center sm:justify-center lg:justify-start ">
              <div className="flex justify-center items-center xl:p-8 lg:p-5 md:p-4  sm:p-8">
                <div className=" w-[160px] sm:w-[18  0px] md:w-[150px] xl:w-[200px]">
                  <img src={polygen} alt="" />
                </div>
                <p className="text-primary absolute text-5xl sm:text-6xl md:text-5xl xl:text-6xl font-semibold">
                  27
                </p>
              </div>
              <div className="sm:p-2 p-5">
                <h4 className=" 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-5xl text-3xl font-extrabold xl:!leading-tight text-w-shadow">
                  Days to treat
                  <span className="block">keep it up!</span>
                </h4>
                <p className="mt-5 bg-white px-3 py-2 inline-block rounded-lg font-semibold text-base sm:text-lg md:text-sm xl:text-base">
                  #EarnItThenBurnIt
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2  xl:h-[320px] overflow-hidden relative">
            <img
              src={featureBg2}
              className="w-full max-h-full object-cover rounded-2xl mx-auto"
              alt=""
            />
            <div className="absolute w-full h-full top-0 flex items-center justify-center sm:justify-center md:justify-start">
              <div className="inline-flex justify-center items-center xl:p-3 md:p-4 lg:p-0 p-3 w-[160px] sm:w-[180px] xl:w-[210px]  object-cover">
                <img src={tShirt} alt="" />
              </div>
              <div className="p-5 sm:p-3 md:p-3 lg:p-4">
                <h4 className="text-3xl sm:text-4xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold xl:!leading-tight text-w-shadow">
                  Get your very own <br />
                  #LittleBlackTshirt
                </h4>
                <p className="mt-2 xl:mt-2 py-2 inline-block rounded-lg font-semibold text-black/60 text-xs sm:text-lg md:text-sm xl:text-base">
                  Pick your inspirational t-shirt to keep you motivated
                  throughout your journey
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col md:flex-row pt-20">
        <div className="w-full md:w-1/2 px-5 md:px-8 lg:pl-20 xl:pl-28 2xl:pl-40 flex flex-col justify-center items-center md:items-start mb-10 md:mb-0">
          <h1 className="text-4xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl 2xl:text-7xl text-white font-bold -tracking-tight">
            #EarnItThenBurnIt
          </h1>
          <p className="text-gray-400 mt-6 -tracking-tighter text-center md:text-start sm:text-lg lg:text-xl ">
            Set countdowns to your next treat -{" "}
            <span className="text-primary block">
              earn, treat, burn, repeat.
            </span>
          </p>
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center relative">
          <img src={mobileBg} alt="" width={500} />
        </div>
      </section>
      <section className="flex justify-end items-center relative">
        <img src={maskGroup} className="absolute" width={400} alt="" />
      </section>
      <section className="flex flex-col md:flex-row ">
        <div className="w-full md:w-1/2 flex justify-center items-center relative order-2 md:order-none">
          <img src={tshirtBunch} alt="" />
        </div>
        <div className="w-full md:w-1/2 px-5 md:pr-20 xl:max-w-2xl flex flex-col justify-center items-center md:items-start mb-10 md:mb-0 mt-20 md:mt-0">
          <h1 className="text-4xl sm:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-white font-semibold -tracking-tight  text-center md:text-start !leading-tight">
            Get your inspirational
            <span className="text-primary font-light block ">
              {" "}
              #LittleBlackTshirt{" "}
            </span>
          </h1>
          <p className="text-gray-400 mt-5 -tracking-tighter text-center md:text-start  sm:text-lg">
            Pick your inspirational t-shirt and show it off when you{" "}
            <span className="text-primary">Glow Up.</span>
          </p>
          <p className="text-white mt-5 -tracking-tighter text-center md:text-start text-sm">
            Available for purchase in app
          </p>
        </div>
      </section>
      <section className="flex justify-start items-center relative w-full">
        <img src={maskGroup1} className="absolute" width={500} alt="" />
      </section>

      <section className="flex flex-col md:flex-row z-10 relative">
        <div className="w-full md:w-1/2 px-5 md:px-8 lg:pl-20 xl:pl-28 2xl:pl-40 flex flex-col justify-center items-center md:items-start mb-10 md:mb-0">
          <h1 className="text-4xl  sm:text-4xl md:text-4xl  lg:text-5xl xl:text-5xl 2xl:text-7xl text-white font-bold -tracking-tight">
            Set target goal
          </h1>
          <p className="text-gray-400 mt-5 -tracking-tighter text-center md:text-start text-lg">
            Set a target weight goal, follow the programme and see{" "}
            <span className="text-primary">visible results.</span>
          </p>
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center relative">
          <img src={weightLoss} alt="" />
        </div>
      </section>
      <section className="relative flex justify-end items-center">
        <img src={maskGroup} className="absolute" width={500} alt="" />
      </section>

      <section className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 flex justify-center items-center relative order-2 md:order-none">
          <img src={supportOther} alt="" />
        </div>
        <div className="w-full md:w-1/2 px-2 xl:max-w-2xl flex flex-col justify-center items-center md:items-start mb-10 md:mb-0 mt-20 md:mt-0">
          <h1 className="text-4xl max-w-2xl sm:w-auto sm:text-4xl md:text-4xl  lg:text-5xl xl:text-5xl 2xl:text-[4.20rem] text-white font-bold -tracking-tight text-center sm:text-start !leading-tight">
            Get support from other
            <span className="text-primary"> #GreatBodies </span>
          </h1>
          <p className="text-gray-400 mt-5 -tracking-tighter text-center md:text-start text-sm sm:text-lg">
            Get support, share progress photos and exercise and meal tips with
            other <span className="text-primary">#GreatBodies.</span>
          </p>
        </div>
      </section>

      <section className="w-full h-full overflow-hidden">
        <img src={workout} className="w-full h-full object-cover" alt="" />
        <div
          className="flex flex-col justify-center items-center"
          id="app_download_section"
        >
          <h2 className="text-3xl sm:text-6xl lg:text-7xl xl:text-8xl text-white -tracking-tighter">
            Workout with
          </h2>
          <h1 className="text-3xl sm:text-6xl lg:text-7xl xl:text-8xl text-[#B6E2A6] sm:mt-5 2xl:mt-2 font-extrabold">
            Great Body Lifestyle
          </h1>
          <h4 className="text-sm sm:text-xl xl:text-2xl text-white sm:my-10 my-5 -tracking-tighter">
            Let's start our journey together now
          </h4>
        </div>
      </section>

      <section>
        <div className="my-10 flex justify-center items-center gap-3">
          <a
            href="https://play.google.com/store/apps/details?id=com.micrasol.greatbodylifestyle"
            target="_blank"
            className="py-2 px-2 sm:px-5 bg-primary flex justify-center items-center gap-2 rounded-2xl cursor-pointer"
            rel="noreferrer"
          >
            <div className="w-8 h-8 bg-black rounded-full flex justify-center items-center">
              <img src={playStore} alt="" />
            </div>
            <p className="font-semibold -tracking-tighter">Play Store</p>
          </a>
          <a
            href="https://apps.apple.com/us/app/great-body-lifestyle/id1658699566"
            target="_blank"
            className="py-2 px-2 sm:px-5 bg-primary flex justify-center items-center gap-2 rounded-2xl cursor-pointer"
            rel="noreferrer"
          >
            <div className="w-8 h-8 bg-black rounded-full flex justify-center items-center">
              <img src={apple} alt="" />
            </div>
            <p className="font-semibold -tracking-tighter">App Store</p>
          </a>
        </div>
      </section>

      <section className="w-full h-full overflow-hidden">
        <img
          src={bunchOfMobile}
          className="w-full h-full object-cover"
          alt=""
        />
      </section>
    </>
  );
};

export default Main;
